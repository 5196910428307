import React from "react";
import Menu from "./MenuItem";
import menuLinks from "../../config/menu";
import styled from "styled-components";
import { mediaMedium } from "../styles/variables";

const Wrapper = styled.nav`
  display: none;

  @media (${mediaMedium}) {
    display: flex;
    align-items: center;
  }
`;

const MainMenu = () => {
  return (
    <Wrapper>
      {menuLinks.map((menuItem, index) => {
        return <Menu key={`menuItem-${index}`} item={menuItem} />;
      })}
    </Wrapper>
  );
};

export default MainMenu;
