import React from "react";
import styled from "styled-components";
import Logo from "../Logo";
import MainMenu from "./MainMenu";
import Container from "../styles/Container";
import NavigationDesktopBtn from "./NavigationDesktopBtn";
import MobileBtn from "./MobileBtn";
import MobileMenu from "./MobileMenu";
import { mediaDesktop, mediaMedium } from "../styles/variables";
import { inject, observer } from "mobx-react";

const Sticky = styled.div`
  background-color: ${(props) =>
    props.active ? "rgb(255 255 255 / 95%)" : "transparent"};
  width: 100%;
  padding: 23px 16px;
  transition: 0.2s background-color;
  max-width: 100vw;

  @media (${mediaMedium}) {
    position: static;
  }
`;

const Wrapper = styled(Container)`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr minmax(32px, 20%);
  align-items: center;
  padding: 0 8px;
  width: 100%;

  @media (${mediaMedium}) {
    grid-template-columns: 1fr minmax(250px, 200px);
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LogoWrapper = styled.div`
  padding-right: 20px;

  @media (${mediaMedium}) {
    max-width: 180px;
  }

  @media (${mediaDesktop}) {
    max-width: 220px;
  }
`;

const MainNavigation = ({ store: { ui } }) => {
  return (
    <Sticky active={ui.navigationSignUpBtn}>
      <Wrapper>
        <Left>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <MainMenu />
        </Left>
        <Right>
          <NavigationDesktopBtn />
          <MobileBtn />
        </Right>
        <MobileMenu />
      </Wrapper>
    </Sticky>
  );
};

export default inject(["store"])(observer(MainNavigation));
