import React from "react";
import MainNavigation from "./Navigation/MainNavigation";
import HeaderPositionWatcher from "./Navigation/HeaderPositionWatcher";
import styled from "styled-components";
import { mediaMedium } from "./styles/variables";
import ZoomInfoHeader from "./ZoomInfoHeader";

const Sticky = styled.div`
  top: 0px;
  position: sticky;
  left: 0;
  z-index: 10;
  width: 100%;

  @media (${mediaMedium}) {
    top: 0;
  }
`;

const Header = () => {
  return (
    <>
      <ZoomInfoHeader />
      <Sticky>
        <HeaderPositionWatcher />
        <MainNavigation />
      </Sticky>
    </>
  );
};

export default Header;
