import React from "react";
import styled from "styled-components";
import { Label18 } from "../styles/BodyTypes";
import { textCTA, textCTAHover } from "../styles/Colors";
import { linkTransition } from "../styles/variables";
import apiEndpoints from "../helpers/apiEndpoints";


const Link = styled.a`
  color: ${textCTA};
  transition: ${linkTransition};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${textCTAHover};
  }
`;

const SignInLink = () => {
  const linkAttrs = {
    as: "a",
    rel: "noopener noreferrer",
    target: "_blank",
    href: apiEndpoints.PARTNERS_URL
  };

  return (
    <Label18 as="div" theme={{ margins: 0 }}>
      <Link {...linkAttrs}>Sign In</Link>
    </Label18>
  );
};

export default SignInLink;
