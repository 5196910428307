import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import omit from "lodash/omit";
import AnimateHeight from "react-animate-height";
import { Label16 } from "../styles/BodyTypes";
import { black1, gray1 } from "../styles/Colors";
import Image from "../Image";
import iconCaret from "../../images/4-icon-caret.svg";
import MobileSubLink from "./MobileSubLink";

const Link = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  position: relative;
`;

const Item = styled.div`
  position: relative;

  &:hover {
    ${Link} {
      &:before {
        opacity: 1;
      }
    }
  }
`;

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  border-bottom: 1px solid ${gray1};
  cursor: pointer;
`;

const Arrow = styled(Image)`
  margin: 0;
  transition: 0.4s linear transform;
  transform-origin: center;
  transform: none;

  &.active {
    transform: rotate(180deg);
  }
`;

const MobileMenuItem = ({ store: { ui }, items }) => {
  const handleClick = (str) => {
    if (ui.hoverMenu === items.title) {
      ui.setHoverMenu("");
      return;
    }
    ui.setHoverMenu(str);
  };

  const closeClick = () => {
    ui.toggleMobileMenu(false);
  };

  const children = items.children;
  items = omit(items, ["children", "titleFooter"]);
  return (
    <Item>
      <Top onClick={() => handleClick(items.title)}>
        <Label16 as="div" theme={{ margins: 0, color: black1 }}>
          <Link {...items}>{items.title}</Link>
        </Label16>
        <Arrow
          width={24}
          height={24}
          alt="show more button"
          src={iconCaret}
          className={ui.hoverMenu === items.title ? "active" : ""}
        />
      </Top>
      {children.length > 0 && (
        <AnimateHeight
          duration={400}
          height={ui.hoverMenu === items.title ? "auto" : 0}
        >
          {children.map((item, index) => {
            const args = omit(item, ["subTitle", "title", "skipFooter"]);

            return (
              <MobileSubLink
                key={`sub-item-${index}`}
                item={item}
                onClick={closeClick}
                {...args}
              />
            );
          })}
        </AnimateHeight>
      )}
    </Item>
  );
};

export default inject(["store"])(observer(MobileMenuItem));
