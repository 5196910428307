import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import omit from "lodash/omit";
import { Label18 } from "../styles/BodyTypes";
import { black1 } from "../styles/Colors";
import SubMenu from "./SubMenu";
import { useMatch } from "@reach/router";

const Link = styled.a`
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  margin: 0 20px;
  position: relative;
  z-index: 2;

  &:after {
    content: "";
    opacity: 0;
    position: absolute;
    bottom: -7px;
    left: 2px;
    width: 100%;
    height: 2px;
    background-color: ${black1};
    transition: 0.3s opacity;
  }

  &.current {
    &:after {
      opacity: 1;
    }
  }
`;

const Item = styled.div`
  position: relative;

  &:hover {
    ${Link} {
      &:before {
        opacity: 1;
      }
    }
  }
`;

const MenuItem = ({ store: { ui }, item }) => {
  const hoverEvent = (str) => {
    ui.setHoverMenu(str);
  };

  let currentSection = useMatch(item.to);

  if (useMatch(`${item.to}:id/`)) {
    currentSection = true;
  }

  const children = item.children;
  item = omit(item, ["children", "titleFooter"]);
  return (
    <Item>
      <Label18
        as="div"
        theme={{ margins: 0, color: black1 }}
        onMouseOver={() => hoverEvent(item.title)}
        onMouseLeave={() => hoverEvent("")}
      >
        <Link className={currentSection ? "current" : ""} {...item}>
          {item.title}
        </Link>
        {children && children.length > 0 && (
          <SubMenu
            hover={ui.hoverMenu === item.title}
            hoverKey={item.title}
            items={children}
          />
        )}
      </Label18>
    </Item>
  );
};

export default inject(["store"])(observer(MenuItem));
