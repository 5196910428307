import React from "react";

const ZoomInfoFooter = () => {
  return (
    <noscript>
    {`<img src="https://ws.zoominfo.com/pixel/WXbRLWOwJi8iBHibFNhp" width="1" height="1" style="display: none;" />`}
    </noscript>
  );
};

export default ZoomInfoFooter;
