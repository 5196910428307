import React from "react";
import { useMatch } from "@reach/router";
import styled from "styled-components";
import { Label16 } from "../styles/BodyTypes";
import { black1, textCTA, textCTAHover, white } from "../styles/Colors";
import { linkTransition } from "../styles/variables";

const SubLinkRow = styled.div`
  display: flex;
  height: 44px;
  align-items: center;
  padding-left: 24px;
`;

const SubLink = styled(Label16)`
  display: inline-block;
  margin: 0;
  color: ${textCTA};
  text-decoration: none;
  transition: ${linkTransition};
  position: relative;
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${white};
  }

  &.current {
    &:after {
      background-color: ${black1};
    }
  }

  &:hover {
    color: ${textCTAHover};
  }
`;

const MobileSubLink = ({ item, onClick, ...props }) => {
  let currentSection = useMatch(`${item.to ?? "null"}`);

  return (
    <SubLinkRow>
      <SubLink
        className={currentSection ? "current" : ""}
        onClick={onClick}
        {...props}
      >
        {item.title}
      </SubLink>
    </SubLinkRow>
  );
};

export default MobileSubLink;
