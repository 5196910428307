import React from "react";
import styled from "styled-components";
import { Body18 } from "../styles/BodyTypes";
import omit from "lodash/omit";
import { linkTransition, mediaDesktop, mediaMedium } from "../styles/variables";
import { textCTA, textCTAHover, white } from "../styles/Colors";
import { inject, observer } from "mobx-react";

const Wrapper = styled.div`
  transition: 0.3s opacity;
  opacity: 0;
  pointer-events: none;
  width: ${(props) => (props.columns ? "400px" : "250px")};
  position: absolute;
  padding: 58px 0 0;
  top: 0;
  left: -36px;

  @media (${mediaDesktop}) {
    width: ${(props) => (props.columns ? "505px" : "250px")};
  }

  &.hover {
    opacity: 1;
    pointer-events: all;
    z-index: 2;
  }
`;

const Top = styled.div`
  display: block;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: -12px;
    left: 70px;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 12px solid ${white};
  }
`;

const Title = styled(Body18)`
  margin: 0;
  color: ${textCTA};
  transition: ${linkTransition};
  text-decoration: none;

  @media (${mediaMedium}) {
    margin: 0;
  }
`;

const Link = styled(Body18)`
  margin: 0;
  text-decoration: none;

  &:not(:first-child) {
    margin-top: 20px;
  }

  &:hover {
    ${Title} {
      color: ${textCTAHover};
    }
  }
`;

const Rows = styled.div`
  flex: 1 1 50%;
`;

const Grid = styled.div`
  padding: 16px 30px;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(144px, 1fr));
  justify-content: center;
  align-items: stretch;

  @media (${mediaDesktop}) {
    grid-template-columns: repeat(auto-fit, minmax(186px, 1fr));
  }
`;

const Background = styled.div`
  background: ${white};
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.25);
`;

const SubMenu = ({ store: { ui }, hover, items, hoverKey }) => {
  const closeEvent = () => {
    ui.setHoverMenu("");
  };

  let rowsItems = [[], []];
  items.map((item, key) => {
    let args = omit(item, ["title", "subTitle", "skipFooter"]);

    let element = (
      <Link key={`submenu-${key}`} onClick={closeEvent} {...args}>
        <Title as="span">{item.title}</Title>
      </Link>
    );

    rowsItems[key < 3 ? 0 : 1].push(element);
    return "";
  });

  return (
    <Wrapper
      className={hover ? "hover" : "none"}
      onMouseLeave={() => ui.setHoverMenu("")}
      onMouseOver={() => ui.setHoverMenu(hoverKey)}
      columns={rowsItems[1].length > 0}
    >
      <Background>
        <Top />
        <Grid>
          <Rows>{rowsItems[0]}</Rows>
          {rowsItems[1].length > 0 && <Rows>{rowsItems[1]}</Rows>}
        </Grid>
      </Background>
    </Wrapper>
  );
};

export default inject(["store"])(observer(SubMenu));
