import styled from "styled-components";
import { mediaMedium, fontCatamara } from "./variables";
import { gray4, textBlack2 } from "./Colors";

const BodyTypes = styled.p`
  margin: 0;
  display: block;
  font-weight: 300;
  font-family: ${fontCatamara};
  text-decoration: none;

  p,
  li {
    font: inherit;
    margin: inherit;
  }
`;

export const Body28 = styled(BodyTypes)`
  color: ${(props) => props.theme.color ?? textBlack2};
  font-size: 24px;
  line-height: 40px;
  margin: ${(props) =>
    props.theme.mobileMargins ?? props.theme.margins ?? `24px 0`};

  @media (${mediaMedium}) {
    font-size: 28px;
    line-height: 52px;
    margin: ${(props) => props.theme.margins ?? `32px 0`};
  }
`;

export const Body24 = styled(BodyTypes)`
  color: ${(props) => props.theme.color ?? textBlack2};
  font-size: 20px;
  line-height: 36px;
  margin: ${(props) =>
    props.theme.mobileMargins ?? props.theme.margins ?? `20px 0`};

  p,
  li {
    margin: ${(props) =>
      props.theme.mobileMargins ?? props.theme.margins ?? `20px 0`};
  }

  @media (${mediaMedium}) {
    font-size: 24px;
    line-height: 40px;
    margin: ${(props) => props.theme.margins ?? `24px 0`};

    p,
    li {
      margin: ${(props) => props.theme.margins ?? `24px 0`};
    }
  }
`;

export const Body20 = styled(BodyTypes)`
  color: ${(props) => props.theme.color ?? textBlack2};
  font-size: 16px;
  line-height: 28px;
  margin: ${(props) =>
    props.theme.mobileMargins ?? props.theme.margins ?? `28px 0`};

  @media (${mediaMedium}) {
    font-size: 20px;
    line-height: 36px;
    margin: ${(props) => props.theme.margins ?? `20px 0`};
  }
`;

export const Body18 = styled(BodyTypes)`
  color: ${(props) => props.theme.color ?? textBlack2};
  display: ${(props) => props.theme.display ?? "block"};
  font-size: 16px;
  line-height: 28px;
  margin: ${(props) =>
    props.theme.mobileMargins ?? props.theme.margins ?? `28px 0`};

  @media (${mediaMedium}) {
    font-size: 18px;
    line-height: 32px;
    margin: ${(props) => props.theme.margins ?? `18px 0`};
  }
`;

export const Body16 = styled(BodyTypes)`
  color: ${(props) => props.theme.color ?? textBlack2};
  font-size: 16px;
  line-height: 28px;
  margin: ${(props) => props.theme.margins ?? `16px 0`};

  @media (${mediaMedium}) {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const Body14 = styled(BodyTypes)`
  color: ${(props) => props.theme.color ?? textBlack2};
  font-size: 14px;
  line-height: 24px;
  margin: ${(props) => props.theme.margins ?? `14px 0`};
`;

export const Label18 = styled(BodyTypes)`
  color: ${(props) => props.theme.color ?? textBlack2};
  font-size: 16px;
  line-height: 28px;
  display: inline;

  @media (${mediaMedium}) {
    font-size: 18px;
    line-height: 32px;
  }
`;

export const Label16 = styled(BodyTypes)`
  color: ${(props) => props.theme.color ?? textBlack2};
  font-size: 16px;
  line-height: 28px;
  display: inline;

  @media (${mediaMedium}) {
    font-size: 16px;
    line-height: 28px;
  }
`;

export const Label14 = styled(BodyTypes)`
  color: ${(props) => props.theme.color ?? textBlack2};
  font-size: 14px;
  line-height: 24px;
`;

export const Caption = styled(BodyTypes)`
  color: ${(props) => props.theme.color ?? textBlack2};
  font-size: 14px;
  line-height: 24px;
  margin: ${(props) => props.theme.margins ?? `14px 0`};
`;

export const Legal = styled(BodyTypes)`
  color: ${(props) => props.theme.color ?? gray4};
  font-size: 12px;
  line-height: 16px;
  margin: ${(props) => props.theme.margins ?? `12px 0`};

  a {
    text-decoration: none;
    color: inherit;
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }
`;
