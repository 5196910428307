import styled from "styled-components";
import { mediaMedium, fontProxima } from "./variables";
import { textBlack2 } from "./Colors";

const Eyebrows = styled.h3`
  margin: 0;
  display: block;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 3px;
  line-height: 16px;
  font-family: ${fontProxima};
  text-transform: uppercase;
`;

export const Eyebrows20 = styled(Eyebrows)`
  color: ${(props) => props.theme.color ?? textBlack2};

  @media (${mediaMedium}) {
    font-size: 20px;
    letter-spacing: 5px;
    line-height: 24px;
  }
`;

export const Eyebrows16 = styled(Eyebrows)`
  color: ${(props) => props.theme.color ?? textBlack2};

  @media (${mediaMedium}) {
    font-size: 16px;
    letter-spacing: 4px;
    line-height: 20px;
  }
`;

export const Eyebrows12 = styled(Eyebrows)`
  color: ${(props) => props.theme.color ?? textBlack2};

  @media (${mediaMedium}) {
    font-size: 12px;
    letter-spacing: 3px;
    line-height: 16px;
  }
`;
