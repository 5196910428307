import React from "react";
import styled from "styled-components";
import SignInLink from "./SignInLink";
import PrimaryButton from "../PrimaryButton";
import { mediaMedium } from "../styles/variables";
import { inject, observer } from "mobx-react";
import { Link } from "gatsby";
import { useMatch } from "@reach/router";

const Desktop = styled.div`
  display: none;

  @media (${mediaMedium}) {
    display: flex;
    align-items: center;
  }
`;

const BtnWrapper = styled.div`
  padding-left: 40px;
  margin: -13px 0;
`;

const NavigationDesktopBtn = ({ store: { ui } }) => {
  const disableSignUp = useMatch("contact");

  return (
    <Desktop>
      <SignInLink />
      {ui.navigationSignUpBtn && !disableSignUp && (
        <BtnWrapper>
          <PrimaryButton
            attrs={{ as: Link, to: "/contact/", state: { from: "publisher" } }}
            theme={{
              width: "153px",
              height: "46px"
            }}
          >
            Sign Up
          </PrimaryButton>
        </BtnWrapper>
      )}
    </Desktop>
  );
};

export default inject(["store"])(observer(NavigationDesktopBtn));
