import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export default [
  {
    title: "Publishers",
    to: "/publishers/",
    as: Link,
    children: [
      {
        skipFooter: true,
        title: "Overview",
        subTitle: "What We Offer",
        to: "/publishers/",
        as: Link
      },
      {
        title: "Shops",
        subTitle: "Full-Service Commerce",
        to: "/publishers/shops/",
        as: Link
      },
      {
        title: "Affiliate",
        subTitle: "Traditional Approach",
        to: "/publishers/affiliate/",
        as: Link
      },
      {
        title: "StackMedia",
        subTitle: "Boost Earnings",
        to: "/publishers/stack-media/",
        as: Link
      },
      {
        title: "Content Studio",
        subTitle: "Our Content Offering",
        to: "/publishers/content-studio/",
        as: Link
      },
      {
        title: "StackAgency",
        subTitle: "StackAgency",
        to: "/publishers/stackagency/",
        as: Link
      }
    ]
  },
  {
    title: "Brands",
    to: "/brands/",
    as: Link,
    children: [
      {
        skipFooter: true,
        title: "Overview",
        subTitle: "How We Help Brands",
        to: "/brands/",
        as: Link
      },
      {
        title: "Shops",
        subTitle: "Get Listed",
        to: "/brands/shops/",
        as: Link
      },
      {
        title: "StackMedia",
        subTitle: "CPC-Based Offering",
        to: "/brands/stack-media/",
        as: Link
      },
      {
        title: "StackAgency",
        subTitle: "StackAgency",
        to: "/brands/stackagency/",
        as: Link
      }
    ]
  },
  {
    title: "About",
    titleFooter: "Company",
    to: "/about/",
    as: Link,
    children: [
      {
        title: "About",
        subTitle: "Who We Are",
        to: "/about/",
        as: Link
      },
      {
        title: "Careers",
        subTitle: "Work With Us",
        to: "/about/#careers",
        as: AnchorLink
      },
      {
        title: "Blog",
        subTitle: "The Latest Updates",
        href: "https://blog.stackcommerce.com",
        target: "_blank",
        as: "a"
      },
      {
        title: "Help",
        subTitle: "Read the FAQ",
        href: "https://support.stackcommerce.com/hc/en-us/requests/new",
        target: "_blank",
        as: "a"
      },
      {
        title: "Contact",
        subTitle: "Get in Touch",
        to: "/contact/",
        as: Link
      }
    ]
  }
];
