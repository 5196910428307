import React from "react";
import { Helmet } from "react-helmet";

const ZoomInfoHeader = () => {
  return (
    <>
      <Helmet>
        <script>
          var zi = document.createElement('script');
          zi.type = 'text/javascript';
          zi.async = true;
          zi.src = 'https://ws.zoominfo.com/pixel/WXbRLWOwJi8iBHibFNhp';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(zi, s);
        </script>
      </Helmet>
    </>
  );
};

export default ZoomInfoHeader;
