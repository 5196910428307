import React from "react";
import styled from "styled-components";
import omit from "lodash/omit";
import GatsbyImage from "gatsby-image/index";

const css = ` max-width: 100%;
  height: auto;
  border: none;
  outline: none;
  display: block;
  user-select: none;
  margin: 0;`;

const Img = styled.img`
  ${css}
`;

const GImage = styled(GatsbyImage)`
  margin: 0;

  img {
    height: auto;
    max-width: 100%;
  }
`;

const Image = ({ ...props }) => {
  const attributes = { ...props };
  omit(attributes, ["loading", "fadeIn"]);

  if (props.fluid || props.fixed) {
    return (
      <GImage
        {...attributes}
        fadeIn={props.fadeIn ? props.fadeIn : false}
        draggable={false}
      />
    );
  } else {
    return <Img {...props} draggable={false} />;
  }
};

export default Image;
