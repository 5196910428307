import React from "react";
import styled from "styled-components";
import { black1, white } from "../styles/Colors";
import { mediaMedium } from "../styles/variables";
import PrimaryButton from "../PrimaryButton";
import menuLinks from "../../config/menu";
import MobileMenuItems from "./MobileMenuItems";
import { inject, observer } from "mobx-react";

const Wrapper = styled.div`
  transition: opacity 0.3s linear;
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? "all" : "none")};
  width: 600px;
  max-height: 95vh;
  max-width: 75vw;
  position: absolute;
  padding: 16px 30px;
  top: 87px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: ${white};
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.25);
  border-top: 8px solid ${black1};
  z-index: 3;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (max-width: 568px) {
    max-width: 378px;
    width: 95vw;
  }

  @media (${mediaMedium}) {
    display: none;
  }
`;

const BtnWrapper = styled.div`
  margin: 16px auto;
`;

const Row = styled.div`
  position: relative;
  z-index: 2;
  background: ${white};
`;

const Close = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
`;

const MobileMenu = ({ store: { ui } }) => {
  const closeEvent = () => {
    ui.toggleMobileMenu(false);
    ui.setHoverMenu("");
  };

  return (
    <>
      {ui.mobileMenuOpened && <Close onClick={closeEvent} />}
      <Wrapper active={ui.mobileMenuOpened}>
        <Row>
          {menuLinks.map((menuItem, index) => {
            return (
              <MobileMenuItems key={`menuItem-${index}`} items={menuItem} />
            );
          })}
        </Row>
        <Row>
          <BtnWrapper>
            <PrimaryButton
              attrs={{ as: "a", href: "#" }}
              theme={{
                width: "100%",
                height: "52px",
                background: black1,
                backgroundHover: black1,
                outline: true,
                color: black1
              }}
            >
              Sign In
            </PrimaryButton>
          </BtnWrapper>
          <BtnWrapper>
            <PrimaryButton
              attrs={{ as: "a", href: "#" }}
              theme={{
                width: "100%",
                height: "52px"
              }}
            >
              Sign Up
            </PrimaryButton>
          </BtnWrapper>
        </Row>
      </Wrapper>
    </>
  );
};

export default inject(["store"])(observer(MobileMenu));
