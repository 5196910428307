import React from "react";
import styled from "styled-components";
import { Label18 } from "./styles/BodyTypes";
import { brandColor1, brandColor1Shade, white } from "./styles/Colors";

const Button = styled(Label18)`
  transition: color 0.2s linear, background 0.2s linear;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.theme.background ?? brandColor1};
  background-color: ${(props) =>
    props.theme.outline
      ? "transparent"
      : props.theme.background ?? brandColor1};
  color: ${(props) => props.theme.color ?? white};
  width: ${(props) => props.theme.width ?? "192px"};
  height: ${(props) => props.theme.height ?? "57px"};
  max-width: 100%;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  font-weight: 600;

  &:hover {
    background-color: ${(props) =>
      props.theme.backgroundHover ?? brandColor1Shade};
    border-color: ${(props) => props.theme.backgroundHover ?? brandColor1Shade};
    color: ${white};
  }
`;

const PrimaryButton = ({ theme, attrs, children }) => {
  return (
    <Button theme={theme} {...attrs}>
      {children}
    </Button>
  );
};

export default PrimaryButton;
