// Site Colors
export const black1 = `#181818`;
export const black2 = `#494949`;
export const gray1 = `#969696`;
export const gray2 = `#e7e7e7`;
export const gray3 = `#eeeeee`;
export const gray4 = `#f5f5f5`;
export const gray5 = `#f9f9f9`;
export const white = `#ffffff`;
export const brandColor1 = `#d3401f`;
export const brandColor1Shade = `#b2361a`;
export const brandColor1Tint = `#ff7052`;
export const brandColor2 = `#184179`;
export const brandColor2Shade = `#143766`;
export const brandColor2Tint = `#466793`;
export const brandColor3 = `#ffc900`;
export const brandColor3Shade = `#d9ab00`;
export const brandColor3Tint = `#ffd333`;
export const error = `#ea3737`;

// Typography variables
export const textBlack1 = black1;
export const textBlack2 = black2;
export const textGray1 = gray1;
export const textGray3 = gray3;
export const textWhite = white;
export const textCTA = brandColor1Shade;
export const textCTAHover = brandColor1;

// Background variables
