export const mediaDesktop = `min-width: 1024px`;
export const mediaMedium = `min-width: 830px`;
export const mediaExtraSmall = `max-width: 360px`;
export const mediaSmall = `max-width: 480px`;
export const mediaMobile = `max-width: 575px`;
export const mediaTablet = `min-width: 576px`;
export const fontProxima = `Proxima-Nova, sans-serif`;
export const fontCatamara = `Catamaran, sans-serif`;
export const linkTransition = `color 0.2s linear`;
export const linesSkew = `-28deg`;
