import React from "react";
import styled from "styled-components";
import logo from "../images/1-stackcommerce-logo.svg";
import { Link } from "gatsby";

const Img = styled.img`
  display: block;
  height: auto;
  max-width: 100%;
`;

const Wrapper = styled(Link)`
  text-decoration: none;
  display: inline-block;
`;

const Logo = () => {
  return (
    <Wrapper to="/">
      <Img
        src={logo}
        width={199}
        height={22}
        draggable={false}
        alt="StackCommerce"
      />
    </Wrapper>
  );
};

export default Logo;
