import React from "react";
import styled from "styled-components";
import iconMenu from "../../images/2-icon-menu.svg";
import iconClose from "../../images/3-icon-close.svg";
import { inject, observer } from "mobx-react";
import { mediaMedium } from "../styles/variables";
import Image from "../Image";

const Btn = styled.span`
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }

  @media (${mediaMedium}) {
    display: none;
  }
`;

const MobileBtn = ({ store: { ui } }) => {
  return (
    <Btn
      onClick={() => ui.toggleMobileMenu(!ui.mobileMenuOpened)}
      className={ui.mobileMenuOpened ? "active" : ""}
    >
      <Image
        width={24}
        height={24}
        alt="menu"
        src={ui.mobileMenuOpened ? iconClose : iconMenu}
      />
    </Btn>
  );
};

export default inject(["store"])(observer(MobileBtn));
