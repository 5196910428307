import React, { PureComponent, createRef } from "react";

class LazyLoadSection extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      observerObject: null,
      visible: false
    };

    this.handleIntersect = this.handleIntersect.bind(this);

    this.refWrapper = createRef();
  }

  componentDidMount() {
    this.createObserver();
  }

  componentWillUnmount() {
    if (this.state.observerObject) {
      this.state.observerObject.disconnect();
    }
  }

  createObserver() {
    let observer;
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: [0]
    };

    observer = new IntersectionObserver(this.handleIntersect, options);
    observer.observe(this.refWrapper.current);
    this.setState({ observerObject: observer });
  }

  handleIntersect(entries) {
    const length = entries.length;
    for (let i = 0; i < length; i++) {
      const entry = entries[i];
      if (entry.isIntersecting) {
        this.state.observerObject.disconnect();
        this.setState({ observerObject: null, visible: true });
      }
    }
  }

  render() {
    // const First = typeof this.props.static || "";

    return (
      <div ref={this.refWrapper}>
        {this.props.static}
        {this.state.visible && this.props.children}
      </div>
    );
  }
}

export default LazyLoadSection;
