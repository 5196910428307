import { PureComponent } from "react";
import { inject, observer } from "mobx-react";

class HeaderPositionWatcher extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      ignoreArea: 100
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, {
      passive: true
    });
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (!this.state.ready) {
      this.setState({ ready: true });
      return false;
    }
    const { ui } = this.props.store;
    const { ignoreArea } = this.state;

    const currentPosition = window.pageYOffset;
    ui.toggleNavigationSignUpBtn(currentPosition > ignoreArea);
  }

  render() {
    return "";
  }
}

export default inject(["store"])(observer(HeaderPositionWatcher));
