import React from "react";
import styled from "styled-components";
import menuLinks from "../../config/menu";
import { Eyebrows12 } from "../styles/Eyebrows";
import { Body18 } from "../styles/BodyTypes";
import { textGray1, white } from "../styles/Colors";
import { linkTransition, mediaMedium } from "../styles/variables";
import omit from "lodash/omit";
import { Link as GatsbyLink } from "gatsby";

const Wrapper = styled.div`
  display: grid;
  justify-content: flex-end;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  grid-gap: 32px;

  @media (${mediaMedium}) {
    padding-left: 24%;
    grid-column-gap: 24px;
  }
`;

const Link = styled(Body18)`
  display: inline-block;
  color: ${textGray1};
  margin: 8px 0;
  transition: ${linkTransition};
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const Top = styled.div`
  margin: 0 0 12px;

  a {
    text-decoration: none;
    transition: ${linkTransition};
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
`;

const FooterLinks = () => {
  return (
    <Wrapper>
      {menuLinks.map((items, index) => {
        return (
          <div key={`footer-links-${index}`}>
            <Top>
              {items.to && (
                <Eyebrows12
                  as={GatsbyLink}
                  to={items.to}
                  theme={{ color: white }}
                >
                  {items.titleFooter || items.title}
                </Eyebrows12>
              )}
              {!items.to && (
                <Eyebrows12 theme={{ color: white }}>
                  {items.titleFooter || items.title}
                </Eyebrows12>
              )}
            </Top>
            {items.children.map((link, keyItem) => {
              const args = omit(link, ["subTitle", "title", "skipFooter"]);
              if (link.skipFooter) {
                return "";
              }

              return (
                <div key={`footer-link-${keyItem}`}>
                  <Link
                    {...args}
                    theme={{
                      display: "inline-block",
                      margins: "8px 0"
                    }}
                  >
                    {link.title}
                  </Link>
                </div>
              );
            })}
          </div>
        );
      })}
    </Wrapper>
  );
};

export default FooterLinks;
