import styled from "styled-components";
import { mediaMedium, fontProxima, mediaExtraSmall } from "./variables";
import { textBlack1 } from "./Colors";

const Heading = styled.h2`
  font-weight: 900;
  line-height: 1;
  margin: 0;
  display: block;
  font-family: ${fontProxima};
  text-transform: uppercase;
`;

export const Headline100 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  font-size: 44px;
  letter-spacing: -1.5px;
  line-height: 52px;

  @media (${mediaMedium}) {
    font-size: 100px;
    letter-spacing: -3px;
    line-height: 104px;
  }

  @media (${mediaExtraSmall}) {
    font-size: 44px;
    letter-spacing: -1.2px;
  }
`;

export const Headline96 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  font-size: 44px;
  letter-spacing: -1.2px;
  margin: ${(props) => props.theme.margins ?? `0`};

  @media (${mediaMedium}) {
    font-size: 96px;
    letter-spacing: -2.8px;
    line-height: 96px;
  }

  @media (${mediaExtraSmall}) {
    line-height: 52px;
    font-size: 40px;
    letter-spacing: -1px;
  }
`;

export const Headline80 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  font-size: 40px;
  letter-spacing: -1px;

  @media (${mediaMedium}) {
    font-size: 80px;
    letter-spacing: -2.4px;
    line-height: 94px;
  }

  @media (${mediaExtraSmall}) {
    font-size: 36px;
    letter-spacing: -0.9px;
  }
`;

export const Headline64 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  font-size: 36px;
  letter-spacing: -0.9px;
  max-width: ${(props) => props.theme.maxWidth ?? ''};

  @media (${mediaMedium}) {
    font-size: 64px;
    letter-spacing: -1.6px;
    line-height: 64px;
  }

  @media (${mediaExtraSmall}) {
    font-size: 32px;
    letter-spacing: -0.8px;
    line-height: 40px;
  }
`;

export const Headline56 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  font-size: 56px;
  letter-spacing: -1.5px;
  line-height: 52px;
`;

export const Headline48 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  font-size: 48px;
  letter-spacing: -1.3px;
`;

export const Headline44 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  font-size: 44px;
  letter-spacing: -1.2px;
`;

export const Headline40 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  margin: ${(props) => props.theme.margins ?? `32px 0`};
  font-size: 32px;
  letter-spacing: -0.8px;
  line-height: 32px;

  @media (${mediaMedium}) {
    font-size: 40px;
    letter-spacing: -1px;
    line-height: 52px;
  }
`;

export const Headline36 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  font-size: 36px;
  letter-spacing: -0.9px;
`;

export const Headline32 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  margin: ${(props) =>
    props.theme.mobileMargins ?? props.theme.margins ?? `28px 0`};
  font-size: 28px;
  letter-spacing: -0.7px;
  line-height: 36px;

  @media (${mediaMedium}) {
    font-size: 32px;
    letter-spacing: -0.8px;
    margin: ${(props) => props.theme.margins ?? `32px 0`};
    line-height: 40px;
  }
`;

export const Headline28 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  margin: ${(props) => props.theme.margins ?? `28px 0`};
  font-size: 28px;
  letter-spacing: -0.7px;
  line-height: 36px;
`;

export const Headline16 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  margin: ${(props) =>
    props.theme.mobileMargins ?? props.theme.margins ?? `14px 0`};
  font-size: 14px;
  letter-spacing: -0.3px;

  @media (${mediaMedium}) {
    font-size: 16px;
    letter-spacing: -0.4px;
    margin: ${(props) => props.theme.margins ?? `16px 0`};
  }
`;

export const Headline14 = styled(Heading)`
  color: ${(props) => props.theme.color ?? textBlack1};
  margin: ${(props) => props.theme.margins ?? `14px 0`};
  font-size: 14px;
  letter-spacing: -0.3px;
`;
