import React from "react";
import styled from "styled-components";
import FooterLinks from "./Navigation/FooterLinks";
import Container from "./styles/Container";
import { black1, white } from "./styles/Colors";
import { mediaMedium } from "./styles/variables";
import { Body18, Legal } from "./styles/BodyTypes";
import Image from "./Image";
import { Link } from "gatsby";
import loadable from "@loadable/component";
import LazyLoadSection from "./LazyLoadSection";
import logo from "../images/1-stackcommerce-logo-white.svg";
import iconLinkedIn from "../images/6-icon-linkedin.svg";
import iconTwitter from "../images/5-icon-twitter.svg";
import iconInstagram from "../images/84-icon-instagram.svg";
import ZoomInfoFooter from "./ZoomInfoFooter";

const FooterNewsletter = loadable(() => import("./forms/FooterNewsletter"));

const Wrapper = styled.div`
  background-color: ${black1};
  padding: 93px 0 96px;

  @media (${mediaMedium}) {
    padding: 120px 0;
  }
`;

const Grid = styled.div`
  @media (${mediaMedium}) {
    display: grid;
    grid-template-columns: minmax(280px, 360px) 1fr;
  }
`;

const Space = styled.div`
  margin: 47px 0;
  height: 1px;

  @media (${mediaMedium}) {
    display: none;
  }
`;

const Bottom = styled.div`
  width: 264px;
  margin-top: 66px;

  @media (${mediaMedium}) {
    margin-top: 82px;
  }
`;

const Social = styled.div`
  display: flex;
  align-items: center;

  a {
    transition: 0.2s opacity;

    &:hover {
      opacity: 0.8;
    }
  }

  a:nth-child(2) {
    margin: 0 18px 0 22px;
  }
`;

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <Grid>
          <LazyLoadSection>
            <FooterNewsletter />
          </LazyLoadSection>
          <Space />
          <FooterLinks />
        </Grid>
        <Bottom>
          <Link to="/">
            <Image
              loading="lazy"
              src={logo}
              alt="stackcommerce"
              width="199px"
              height="22px"
            />
          </Link>
          <Body18 theme={{ color: white }}>
            Award-Winning Content and Commerce Platform
          </Body18>

          <Social>
            <a
              href="https://www.linkedin.com/company/stackco"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Image
                loading="lazy"
                alt="LinkedIn"
                src={iconLinkedIn}
                width={20}
                height={20}
              />
            </a>
            <a
              href="https://twitter.com/stackcommerce"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Image
                loading="lazy"
                alt="X, formerly Twitter"
                src={iconTwitter}
                width={25}
                height={20}
              />
            </a>
            <a
              href="https://www.instagram.com/stackcommerce/"
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <Image
                loading="lazy"
                alt="Instagram"
                src={iconInstagram}
                width={25}
                height={20}
              />
            </a>
          </Social>
          <Legal theme={{ margins: "40px 0 0" }}>
            &copy; {new Date().getFullYear()} StackCommerce <br />
            <Link to="/privacy/">Privacy Policy</Link>
            {" | "}
            <Link to="/site-terms/">Terms</Link>
          </Legal>
          <ZoomInfoFooter />
        </Bottom>
      </Container>
    </Wrapper>
  );
};

export default Footer;
