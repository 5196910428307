import styled from "styled-components";
import { mediaMedium } from "./variables";

const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1144px;
  width: 100%;
  margin: 0 auto;

  @media (${mediaMedium}) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export default Container;
